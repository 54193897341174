import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { withStyles } from '@material-ui/styles'
import { Grid, Button } from '@material-ui/core'

const styles = theme => ({
  messageGrid: {
    backgroundImage: `url(${require('../../assets/bg_maintenance.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: '100vh',
    width: '100%',
    paddingBottom: 100,
  },
  offlineGrid: {
    marginTop: 100,
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    backgroundImage: `url(${require('../../assets/logo_tws.png')})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 250,
    height: 150,
  },
  offline: {
    marginTop: 50,
    textAlign: 'center',
    color: '#0075c1',
    fontWeight: 700,
    fontSize: 62,
  },
  message: {
    textAlign: 'center',
    fontWeight: 300,
    fontSize: 22,
  },
  buttonGrid: {
    marginTop: 60,
    textAlign: 'center',
    color: '#3a85c5',
    fontWeight: 300,
    fontSize: 16,
  },
  homeButton: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    borderColor: '#3a85c5',
    borderWidth: 2,
    borderRadius: 50,
    color: '#3a85c5',
    fontWeight: 600,
    fontSize: 16,
    '&:hover': {
      backgroundColor: '#eef7ff',
    },
  },
  iconDiv: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: 12,
  },
  icon: {
    backgroundImage: `url(${require('../../assets/error_arrow.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 25,
    height: 25,
  },
})

export default withRouter((withStyles(styles)(class extends Component {
  // constructor() {
  //   super()
  // }

  componentDidMount() {
    document.title = `${this.props.title}`
  }

  render() {
    const {
      classes,
      title,
      description
    } = this.props

    return (
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="stretch"
        >
          <Grid item xs={12} className={classes.messageGrid}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >

              <Grid item xs={12} className={classes.offlineGrid}>
                <div className={classes.logo} />
              </Grid>

              <Grid item xs={12} className={classes.offline}>
                Oops!
              </Grid>

              <Grid item xs={9} sm={12} className={classes.message}>
                {"Sorry, we couldn't find what you were looking for."}
              </Grid>

              <Grid item xs={12} className={classes.buttonGrid}>
                <Link
                  to='/'
                  style={{ textDecoration: 'none' }}
                >
                  <Button
                    variant="outlined"
                    className={classes.homeButton}
                  >
                    GO BACK HOME
                    <div className={classes.iconDiv}>
                      <div className={classes.icon} />
                    </div>
                  </Button>
                </Link>
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

})))
