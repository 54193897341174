import React, { Component } from 'react'

import GoogleMapReact from 'google-map-react'

const google = window.google
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY
const bootstrapURLKeys = {
  key: GOOGLE_API_KEY
}
const mapProps = {
  center: {
    lat: 14.5858317,
    lng: 121.0618613
  },
  zoom: 15,
}

export default (class extends Component {
  // constructor() {
  //   super()
  // }

  handleApiLoaded = (map, maps) => {
    const marker = new google.maps.Marker({
      position: mapProps.center,
      map: map,
    })
    return marker
  }

  render() {
    return (
      <div style= {{ height: '100%', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={bootstrapURLKeys}
          center={mapProps.center}
          zoom={mapProps.zoom}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
        >
        </GoogleMapReact>
      </div>
    )
  }

})
