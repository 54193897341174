import React, { Component } from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import { withStyles } from '@material-ui/styles'
import { CssBaseline } from '@material-ui/core'

import Home from './Home'
import About from './About'
import Services from './Services'
import Contact from './Contact'
import Maintenance from './Maintenance'
import Error from './Error'

const history = createBrowserHistory()
const styles = theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    backgroundColor: '#ffffff',
  },
})

const description = "Total Water Solutions, Inc. is engaged in the business of water supply purification, bulk supply &amp; distribution, and wastewater &amp; biosolids treatment and disposal."

export default withStyles(styles)(class extends Component {
  constructor() {
    super()
    this.state = {
      maintenance: false,
    }
  }

  render() {
    const {
      classes
    } = this.props

    const {
      maintenance
    } = this.state

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Router history={history}>
          {maintenance
            ? (
              <Switch>
                <Route
                  exact path={'/'}
                  render={(props) =>
                    <Maintenance
                      {...props}
                      title={"Total Water Solutions, Inc."}
                      description={description}
                    />
                  }
                >
                </Route>

                <Route
                  render={(props) => <Redirect to='/' />}
                >
                </Route>
              </Switch>
            )
            : (
              <Switch>
                <Route
                  exact path={'/'}
                  render={(props) =>
                    <Home
                      {...props}
                      title={"Total Water Solutions, Inc."}
                      description={description}
                    />
                  }
                >
                </Route>

                <Route
                  exact path={'/about'}
                  render={(props) =>
                    <About
                      {...props}
                      title={"About | Total Water Solutions, Inc."}
                      description={description}
                    />
                  }
                >
                </Route>

                <Route
                  exact path={'/services'}
                  render={(props) =>
                    <Services
                      {...props}
                      title={"Services | Total Water Solutions, Inc."}
                      description={description}
                    />
                  }
                >
                </Route>

                <Route
                  exact path={'/contact-us'}
                  render={(props) =>
                    <Contact
                      {...props}
                      title={"Contact Us | Total Water Solutions, Inc."}
                    />
                  }
                >
                </Route>

                <Route
                  exact path={'/404'}
                  render={(props) =>
                    <Error
                      {...props}
                      title={"Total Water Solutions, Inc."}
                      description={description}
                    />
                  }
                >
                </Route>

                <Route
                  render={(props) => <Redirect to='/404' />}
                >
                </Route>

              </Switch>
            )
          }
        </Router>
      </div>
    )
  }
})
