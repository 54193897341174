import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { withStyles } from '@material-ui/styles'
import { Divider, Grid, Hidden, Button } from '@material-ui/core'

import AppBar from '../components/AppBar'
import Footer from '../components/Footer'
import ContactForm from './ContactForm'
import GoogleMaps from '../GoogleMaps'

const styles = theme => ({
  dividerGrid: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  divider: {
    backgroundColor: '#f6e7d7',
    height: 2,
    width: '70%',
    borderRadius: 50,
  },
  contactGrid: {
    marginTop: 15,
    marginBottom: 15,
  },
  mapGrid: {
    height: 500,
    width: 450,
  },
  mapGridCompact: {
    height: 300,
    minWidth: 200,
  },
  contactInfoGrid: {
    marginTop: 30,
    marginBottom: 15,
    fontSize: 16,
  },
  contactHeader1x: {
    color: '#a77c50',
    fontWeight: 300,
    fontSize: 20,
    letterSpacing: 6,
    marginTop: 20,
    marginBottom: 40,
  },
  contactHeader2x: {
    color: '#a77c50',
    fontWeight: 300,
    fontSize: 20,
    letterSpacing: 6,
    marginBottom: 40,
  },
  contactCompany: {
    fontWeight: 500,
    fontSize: 22,
    marginTop: 25,
    marginBottom: 25,
  },
  contactMargin: {
    marginTop: 40,
  },
  contactButton: {
    backgroundColor: '#3a85c5',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 50,
    fontWeight: 700,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#3a85c5',
    },
  },
  contactDetailsGrid: {
    paddingLeft: 8,
    paddingRight: 8,
    // backgroundColor: '#c0dbf1',
  },
  iconGrid: {
    display: 'flex',
    justifyContent: 'center',
    // backgroundColor: '#c0dbf1',
  },
  icon1: {
    backgroundImage: `url(${require('../../assets/contact_marker.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 25,
    height: 25,
  },
  icon2: {
    backgroundImage: `url(${require('../../assets/contact_talk.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 25,
    height: 25,
  },
  icon3: {
    backgroundImage: `url(${require('../../assets/contact_mail.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 25,
    height: 25,
  },
})

export default withRouter((withStyles(styles)(class extends Component {
  constructor() {
    super()
    this.state = {
      open: false
    }
  }

  componentDidMount() {
    document.title = `${this.props.title}`
  }

  handleOnClick = () => {
    this.setState({
      open: true
    })
  }

  handleClose = () => {
    this.setState({
      open: false
    })
  }

  render() {
    const {
      classes,
      title
    } = this.props

    const {
      open
    } = this.state

    return (
      <div className={classes.background}>
        <Helmet>
          <title>{title}</title>
        </Helmet>

        <AppBar
          home={false}
          about={false}
          services={false}
          contact={true}
        />

        <ContactForm
          open={open}
          handleClose={this.handleClose}
        />

        <div className={classes.dividerGrid}>
          <Divider variant="middle" className={classes.divider} />
        </div>

        <div>
          <Hidden only={['xs', 'sm']}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-start"
              className={classes.contactGrid}
            >

              <Grid item xs={10} sm={8} md={4} className={classes.contactInfoGrid}>
                <Grid item xs={12} className={classes.contactHeader2x}>
                  CONTACT INFORMATION
                </Grid>

                <Grid item xs={12} className={classes.contactCompany}>
                  Total Water Solutions, Inc.
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                      <Grid item xs={1} className={classes.iconGrid}>
                        <div className={classes.icon1} />
                      </Grid>
                      <Grid item xs={11} className={classes.contactDetailsGrid}>
                        Unit 303 Prestige Tower, F. Ortigas Jr. Road, Ortigas Center, Pasig City
                      </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} className={classes.contactMargin}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                      <Grid item xs={1} className={classes.iconGrid}>
                        <div className={classes.icon2} />
                      </Grid>
                      <Grid item xs={11} className={classes.contactDetailsGrid}>
                        0917 - 508 - TWSI (8974)
                      </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                      <Grid item xs={1} className={classes.iconGrid}>
                        <div className={classes.icon3} />
                      </Grid>
                      <Grid item xs={11} className={classes.contactDetailsGrid}>
                        info@totalwatersolutions-inc.com
                      </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} className={classes.contactMargin}>
                  <Button onClick={this.handleOnClick} className={classes.contactButton}>
                    SEND US A MESSAGE
                  </Button>
                </Grid>

              </Grid>

              <Grid item xs={12} sm={12} md={6} className={classes.mapGrid}>
                <GoogleMaps />
              </Grid>

            </Grid>
          </Hidden>

          <Hidden only={['md', 'lg', 'xl']}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
              className={classes.contactGrid}
            >
              <Grid item xs={12} sm={12} md={6} className={classes.mapGridCompact}>
                <GoogleMaps />
              </Grid>

              <Grid item xs={10} sm={8} md={4} className={classes.contactGrid}>
                <Grid item xs={12} className={classes.contactHeader1x}>
                  CONTACT INFORMATION
                </Grid>

                <Grid item xs={12} className={classes.contactCompany}>
                  Total Water Solutions, Inc.
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                      <Grid item xs={1} className={classes.iconGrid}>
                        <div className={classes.icon1} />
                      </Grid>
                      <Grid item xs={11} className={classes.contactDetailsGrid}>
                        Unit 303 Prestige Tower, F. Ortigas Jr. Road, Ortigas Center, Pasig City
                      </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} className={classes.contactMargin}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                      <Grid item xs={1} className={classes.iconGrid}>
                        <div className={classes.icon2} />
                      </Grid>
                      <Grid item xs={11} className={classes.contactDetailsGrid}>
                        0917 - 508 - TWSI (8974)
                      </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                      <Grid item xs={1} className={classes.iconGrid}>
                        <div className={classes.icon3} />
                      </Grid>
                      <Grid item xs={11} className={classes.contactDetailsGrid}>
                        info@totalwatersolutions-inc.com
                      </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} className={classes.contactMargin}>
                  <Button onClick={this.handleOnClick} className={classes.contactButton}>
                    SEND US A MESSAGE
                  </Button>
                </Grid>

              </Grid>

            </Grid>
          </Hidden>
        </div>

        <Footer credit={true} />
      </div>
    )
  }
})))
