import React, { Component } from 'react'

import { withStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'


const styles = theme => ({
  footerDiv: {
    position: 'relative',
    top: 0,
    bottom: 0,
  },
  footerGrid: {
    backgroundColor: '#f6f6f6',
    padding: 15,
  },
  footer: {
    fontSize: 12,
    color: '#6e6e6e',
    textAlign: 'right',
  },
  noCredit: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: 9,
    color: '#6e6e6e',
    visibility: 'hidden',
  },
  credit: {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: 9,
    color: '#6e6e6e',
  },
  creditLogo: {
    width: 48,
    height: 12,
    paddingLeft: 2,
    paddingRight: 2,
  }
})


export default withStyles(styles)(class extends Component {
  // constructor() {
  //   super()
  // }

  render() {
    const {
      classes,
      credit
    } = this.props

    return (
      <div className={classes.footerDiv}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          className={classes.footerGrid}
        >
          { credit &&
            <Grid item xs={12} sm={6} className={classes.credit}>
                Icons created by
                <img
                  alt={''}
                  src={require('../../../assets/freepik.png')}
                  className={classes.creditLogo}
                />
            </Grid>
          }

          { !credit &&
            <Grid item xs={12} sm={6} className={classes.noCredit}>
                Icons made by
                <img
                  alt={''}
                  src={require('../../../assets/freepik.png')}
                  className={classes.creditLogo}
                />
            </Grid>
          }


          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="flex-end"
              spacing={2}
            >
              <Grid item xs={12} className={classes.footer}>
                  Copyright © 2023 Total Water Solutions, Inc. - All Rights Reserved.
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
})
