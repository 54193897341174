import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { withStyles } from '@material-ui/styles'
import { Grid, Hidden } from '@material-ui/core'

const styles = theme => ({
  messageGrid: {
    backgroundImage: `url(${require('../../assets/bg_maintenance.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: '100vh',
    width: '100%',
  },
  offlineGrid: {
    marginTop: 100,
    display: 'flex',
    justifyContent: 'center',
  },
  logo: {
    backgroundImage: `url(${require('../../assets/logo_tws.png')})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 250,
    height: 150,
  },
  contact: {
    marginTop: 50,
    textAlign: 'center',
    color: '#3a85c5',
    fontWeight: 300,
    fontSize: 16,
  },
  email: {
    marginBottom: 100,
    textAlign: 'center',
    color: '#3a85c5',
    fontWeight: 700,
    fontSize: 16,
  },


  // size: xs
  offline1x: {
    marginTop: 50,
    textAlign: 'center',
    color: '#0075c1',
    fontWeight: 700,
    fontSize: 48,
  },
  message1x: {
    marginTop: 20,
    textAlign: 'center',
    fontWeight: 300,
    fontSize: 20,
  },


  // size: sm, md, lg, xl
  offline2x: {
    marginTop: 50,
    textAlign: 'center',
    color: '#0075c1',
    fontWeight: 700,
    fontSize: 62,
  },
  message2x: {
    marginTop: 20,
    textAlign: 'center',
    fontWeight: 300,
    fontSize: 22,
  },
})

export default withRouter((withStyles(styles)(class extends Component {
  // constructor() {
  //   super()
  // }

  componentDidMount() {
    document.title = `${this.props.title}`
  }

  render() {
    const {
      classes,
      title,
      description
    } = this.props

    return (
      <div>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="stretch"
        >

          <Hidden only={['sm', 'md', 'lg', 'xl']}>
            <Grid item xs={12} className={classes.messageGrid}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} className={classes.offlineGrid}>
                  <div className={classes.logo} />
                </Grid>

                <Grid item xs={12} className={classes.offline1x}>
                  We are offline.
                </Grid>

                <Grid item xs={12} className={classes.message1x}>
                  Our website is currently
                  <br />undergoing maintenance.
                  <br />We will be back soon!
                </Grid>

                <Grid item xs={12} className={classes.contact}>
                  In the meantime, you may contact us at
                </Grid>

                <Grid item xs={12} className={classes.email}>
                  <a className={classes.email} href="mailto:info@totalwatersolutions-inc.com">info@totalwatersolutions-inc.com.</a>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>

          <Hidden only={['xs']}>
            <Grid item xs={12} className={classes.messageGrid}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} className={classes.offlineGrid}>
                  <div className={classes.logo} />
                </Grid>

                <Grid item xs={12} className={classes.offline2x}>
                  We are offline.
                </Grid>

                <Grid item xs={12} className={classes.message2x}>
                  Our website is currently undergoing maintenance.
                  <br />We will be back soon!
                </Grid>

                <Grid item xs={12} className={classes.contact}>
                  In the meantime, you may contact us at
                </Grid>

                <Grid item xs={12} className={classes.email}>
                  <a className={classes.email} href="mailto:info@totalwatersolutions-inc.com">info@totalwatersolutions-inc.com.</a>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>

        </Grid>
      </div>
    )
  }

})))
