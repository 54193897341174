import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { withStyles } from '@material-ui/styles'
import { Divider, Grid, Paper, Hidden } from '@material-ui/core'

import AppBar from '../components/AppBar'
import Footer from '../components/Footer'

const styles = theme => ({
  dividerGrid: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  divider: {
    backgroundColor: '#f6e7d7',
    height: 2,
    width: '70%',
    borderRadius: 50,
  },
  aboutGrid: {
    textAlign: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  aboutContent: {
    marginTop: 20,
    marginBottom: 20,
    fontWeight: 300,
    fontSize: 22,
  },
  imageGrid: {
    backgroundImage: `url(${require('../../assets/bg_about.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: 200,
  },
  memberContent: {
    marginTop: 30,
    fontWeight: 300,
    fontSize: 18,
  },
  horizontalDivider: {
    backgroundColor: '#c0dbf1',
    height: 2,
    width: '60%',
    marginTop: 20,
    marginBottom: 20,
  },
  verticalDivider: {
    backgroundColor: '#c0dbf1',
    width: 2,
    marginTop: 50,
    marginBottom: 50,
    marginLeft: 5,
    marginRight: 5,
  },
  memberGrid: {
    marginTop: 15,
    marginBottom: 15,
    paddingTop: 15,
    paddingBottom: 15,
  },
  memberHeader: {
    color: '#3a85c5',
    fontWeight: 300,
    fontSize: 20,
    letterSpacing: 6,
    paddingLeft: 20,
  },
  memberName: {
    marginTop: 20,
    paddingLeft: 40,
    fontWeight: 700,
    fontSize: 15,
  },
  memberPosition: {
    paddingLeft: 40,
    fontStyle: 'italic',
  },

  combinedExperiencesGrid: {
    textAlign: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  experiences: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: '#a77c50',
    fontWeight: 700,
    letterSpacing: 6,
    marginTop: 20,
    marginBottom: 10,
    paddingLeft: 10,
  },
  experiencesDivider: {
    backgroundColor: '#a77c50',
    height: 2,
    width: '100%',
  },
  expGrid: {
    // backgroundImage: `url(${require('../../assets/about_-exp.png')})`,
    // backgroundSize: 'cover',
    // backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'center',
    // height: 600,
    marginTop: 15,
    marginBottom: 30,
  },
  expImg2x: {
    // width: 1200,
    // height: 600,
    maxWidth: '100%',
    height: 'auto',
    paddingLeft: 15,
    paddingRight: 15,
  },

})

export default withRouter((withStyles(styles)(class extends Component {
  // constructor() {
  //   super()
  // }

  componentDidMount() {
    document.title = `${this.props.title}`
  }

  render() {
    const {
      classes,
      title,
      description
    } = this.props

    return (
      <div className={classes.background}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>

        <AppBar
          home={false}
          about={true}
          services={false}
          contact={false}
        />

        <div className={classes.dividerGrid}>
          <Divider variant="middle" className={classes.divider} />
        </div>

        <div>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.aboutGrid}
          >
            <Grid item xs={8} sm={7} md={7} lg={5} className={classes.aboutContent}>
                We are engaged in the business of water supply purification, bulk supply & distribution, and wastewater & biosolids treatment and disposal.
            </Grid>
          </Grid>
        </div>

        <div>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Paper square={true} elevation={0} className={classes.imageGrid}>
              </Paper>
            </Grid>
          </Grid>
        </div>

        <div>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.aboutGrid}
          >
            <Grid item xs={8} className={classes.memberContent}>
                TWSI shareholders and officers are engineers and professionals with long experiences and wide exposure in water and sanitation consulting industry, in the fields of planning, engineering, economics, training, and environment.
            </Grid>

          </Grid>
        </div>

        <div>

          <div>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              className={classes.combinedExperiencesGrid}
            >
              <Grid item xs={10}>
                <div className={classes.experiences}>
                  COMBINED EXPERIENCES
                </div>

                <div className={classes.dividerGrid}>
                  <Divider variant="middle" className={classes.experiencesDivider} />
                </div>
              </Grid>
            </Grid>
          </div>

          <div>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={10} className={classes.expGrid}>
                <img
                  alt={''}
                  src={require('../../assets/about_exp.png')}
                  className={classes.expImg2x}
                />
              </Grid>
            </Grid>
          </div>



        </div>

        <Footer credit={false} />
      </div>
    )
  }
})))
