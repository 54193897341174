import React, { Component } from 'react'
import emailjs from 'emailjs-com'

import { withStyles } from '@material-ui/styles'
import { Dialog, Paper, List, ListItem, TextField, Button } from '@material-ui/core'

const styles = theme => ({
  divider: {
    // backgroundColor: '#3a85c5',
    // height: 6,
  },
  paper: {
    padding: 20,
    backgroundImage: `url(${require('../../../assets/bg_maintenance.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  formTitle: {
    justifyContent: 'center',
    textAlign: 'center',
    fontSize: 22,
    marginTop: 10,
    marginBottom: 10,
  },
  form: {
    marginTop: 10,
    marginBottom: 10,
  },
  buttonsDiv: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
    marginBottom: 10,
  },
  submitButton: {
    backgroundColor: '#3a85c5',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 50,
    fontWeight: 700,
    color: '#ffffff',
    width: '70%',
    '&:hover': {
      backgroundColor: '#3a85c5',
    },
  },
  cancelButton: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    color: '#3a85c5',
    '&:hover': {
      background: 'none',
    },
  }
})

const TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID
const USER_ID = process.env.REACT_APP_EMAILJS_USER_ID

const emailJSProps = {
  serviceID: 'gmail',
  templateID: TEMPLATE_ID,
  userID: USER_ID
}

export default withStyles(styles)(class extends Component {
  constructor() {
    super()
    this.state = {
      name: '',
      email: '',
      subject: '',
      message: '',
    }
  }

  handleChange = (event) => {
    let id = event.target.id
    let value = event.target.value

    this.setState({
      [id]: value
    })
  }

  onSubmit = (event) => {
    event.preventDefault()

    let inquiryParams = {
      name: this.state.name,
      email: this.state.email,
      subject: this.state.subject,
      message: this.state.message
    }

    emailjs.send(emailJSProps.serviceID, emailJSProps.templateID, inquiryParams, emailJSProps.userID)
      .then((result) => {
        // console.log(result.text)
      })
      .catch((error) => {
        console.error(error)
      })

    this.props.handleClose()
  }

  render() {
    const {
      classes,
      open,
      handleClose,
    } = this.props

    return (
      <div>
        <Dialog
          fullWidth
          open={open}
          onClose={handleClose}
        >

          <div className={classes.divider} />
          <Paper
            square={true}
            elevation={3}
            className={classes.paper}
          >

            <div className={classes.formTitle}>
              Get in touch
            </div>

            <div>
              <form
                onSubmit={this.onSubmit.bind(this)}
                className={classes.form}
              >

                <List>
                  <ListItem>
                    <TextField
                      fullWidth
                      autoFocus
                      required
                      id={'name'}
                      label={'Name'}
                      variant="outlined"
                      onChange={this.handleChange}
                    />
                  </ListItem>

                  <ListItem>
                    <TextField
                      fullWidth
                      required
                      id={'email'}
                      label={'Email'}
                      variant="outlined"
                      onChange={this.handleChange}
                    />
                  </ListItem>

                  <ListItem>
                    <TextField
                      fullWidth
                      required
                      id={'subject'}
                      label={'Subject'}
                      variant="outlined"
                      onChange={this.handleChange}
                    />
                  </ListItem>

                  <ListItem>
                    <TextField
                      fullWidth
                      multiline
                      rows={3}
                      id={'message'}
                      label={'What can we help you with?'}
                      variant="outlined"
                      onChange={this.handleChange}
                    />
                  </ListItem>
                </List>

                <div className={classes.buttonsDiv}>
                  <Button
                    type="submit"
                    fullWidth
                    className={classes.submitButton}
                  >
                    Submit
                  </Button>
                </div>

                <div className={classes.buttonsDiv}>
                  <Button
                    disableRipple
                    className={classes.cancelButton}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>

              </form>
            </div>
          </Paper>

        </Dialog>
      </div>
    )

  }
})
