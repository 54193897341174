import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { withStyles } from '@material-ui/styles'
import { Divider, Grid, Hidden, Paper } from '@material-ui/core'

import AppBar from '../components/AppBar'
import Footer from '../components/Footer'

const styles = theme => ({
  dividerGrid: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  divider: {
    backgroundColor: '#f6e7d7',
    height: 2,
    width: '70%',
    borderRadius: 50,
  },
  header: {
    marginTop: 40,
    marginBottom: 15,
    textAlign: 'center',
    color: '#a77c50',
    fontWeight: 300,
    fontSize: 20,
    letterSpacing: 6,
  },
  businessScopeLeft: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 18,
    textAlign: 'right',
    paddingRight: 10,
  },
  businessScopeRight: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 18,
    textAlign: 'left',
    paddingLeft: 10,
  },
  businessScopeSubtitle: {
    fontSize: 12,
    fontWeight: 200,
    fontStyle: 'italic',
  },

  businessScopeLogoGrid: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  businessScopeLogo1: {
    backgroundImage: `url(${require('../../assets/bs1.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 100,
    width: 100,
    marginTop: 10,
    marginBottom: 10,
  },
  businessScopeLogo2: {
    backgroundImage: `url(${require('../../assets/bs2.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 100,
    width: 100,
    marginTop: 10,
    marginBottom: 10,
  },
  businessScopeLogo3: {
    backgroundImage: `url(${require('../../assets/bs3.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 100,
    width: 100,
    marginTop: 10,
    marginBottom: 10,
  },
  businessScopeLogo4: {
    backgroundImage: `url(${require('../../assets/bs4.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 100,
    width: 100,
    marginTop: 10,
    marginBottom: 10,
  },
  businessScopeLogo5: {
    backgroundImage: `url(${require('../../assets/bs5.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 100,
    width: 100,
    marginTop: 10,
    marginBottom: 10,
  },
  businessScopeLogo6: {
    backgroundImage: `url(${require('../../assets/bs6.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 100,
    width: 100,
    marginTop: 10,
    marginBottom: 10,
  },

  businessScopeGrid: {
    textAlign: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  experiences: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: '#3a85c5',
    fontWeight: 700,
    letterSpacing: 6,
    marginTop: 20,
    marginBottom: 10,
    paddingLeft: 10,
  },
  experiencesDivider: {
    backgroundColor: '#0075C1',
    height: 2,
    width: '100%',
  },
  experienceHeader: {
    marginTop: 15,
    marginBottom: 15,
    color: '#3a85c5',
    fontWeight: 700,
    fontSize: 20,
  },
  experienceContent: {
    fontWeight: 300,
    fontSize: 18,
    marginTop: 15,
    marginBottom: 15,
  },
  wssomGrid: {
    backgroundColor: '#c4c4c4',
    // backgroundImage: `url(${require('../../assets/bg_pd.png')})`,
    // backgroundSize: 'cover',
    // backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'center',
    minHeight: 200,
    marginTop: 15,
    marginBottom: 15,
  },
  csGrid: {
    backgroundImage: `url(${require('../../assets/bg_cs.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: 200,
    marginTop: 15,
    marginBottom: 15,
  },
  pdGrid: {
    backgroundImage: `url(${require('../../assets/bg_pd.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: 200,
    marginTop: 15,
    marginBottom: 15,
  },
  constructionGrid: {
    backgroundColor: '#c4c4c4',
    backgroundImage: `url(${require('../../assets/bg_cp.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    minHeight: 200,
    marginTop: 15,
    marginBottom: 15,
  },
  projectGrid: {
    marginTop: 10,
    marginBottom: 10,
  },
  projectItemGrid: {
    textAlign: 'left',
  },
  projectTitle: {
    marginTop: 15,
    marginBottom: 15,
    marginLeft: 20,
    marginRight: 10,
    fontWeight: 700,
    fontSize: 16,
  },
  projectDescription: {
    marginLeft: 20,
    marginRight: 20,
  },
  groundwaterSubtitle: {
    fontSize: 14,
    fontStyle: 'italic',
    fontWeight: 200,
  },
})

export default withRouter((withStyles(styles)(class extends Component {
  // constructor() {
  //   super()
  // }

  componentDidMount() {
    document.title = `${this.props.title}`
  }

  render() {
    const {
      classes,
      title,
      description
    } = this.props

    return (
      <div className={classes.background}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>

        <AppBar
          home={false}
          about={false}
          services={true}
          contact={false}
        />

        <div className={classes.dividerGrid}>
          <Divider variant="middle" className={classes.divider} />
        </div>

        <div>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={7} className={classes.header}>
                BUSINESS SCOPE
            </Grid>

            <Hidden only={['md', 'lg', 'xl']}>
              <Grid item xs={10}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                  spacing={2}
                  className={classes.businessScopeLogoGrid}
                >

                  <Grid item xs={12} sm={10}>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={4} sm={3} className={classes.businessScopeLogoGrid}>
                        <div className={classes.businessScopeLogo1} />
                      </Grid>

                      <Grid item xs={8} sm={9} className={classes.businessScopeRight}>
                        <Grid
                          container
                          direction="column"
                        >
                          <Grid item xs={12}>
                            Technical and Financial Advisory
                          </Grid>

                          <Grid item xs={12} className={classes.businessScopeSubtitle}>
                            Concept planning and business planning
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={10}>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={4} sm={3} className={classes.businessScopeLogoGrid}>
                        <div className={classes.businessScopeLogo2} />
                      </Grid>

                      <Grid item xs={8} sm={9} className={classes.businessScopeRight}>
                        <Grid
                          container
                          direction="column"
                        >
                          <Grid item xs={12}>
                            Training and Capacity Building
                          </Grid>

                          <Grid item xs={12} className={classes.businessScopeSubtitle}>
                            Providing expertise and knowledge transfer
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={10}>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={4} sm={3} className={classes.businessScopeLogoGrid}>
                        <div className={classes.businessScopeLogo3} />
                      </Grid>

                      <Grid item xs={8} sm={9} className={classes.businessScopeRight}>
                        <Grid
                          container
                          direction="column"
                        >
                          <Grid item xs={12}>
                            Project Scoping
                          </Grid>

                          <Grid item xs={12} className={classes.businessScopeSubtitle}>
                            Streamlining client needs to manage risks
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={10}>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={4} sm={3} className={classes.businessScopeLogoGrid}>
                        <div className={classes.businessScopeLogo4} />
                      </Grid>

                      <Grid item xs={8} sm={9} className={classes.businessScopeRight}>
                        <Grid
                          container
                          direction="column"
                        >
                          <Grid item xs={12}>
                            Project Development and Packaging
                          </Grid>

                          <Grid item xs={12} className={classes.businessScopeSubtitle}>
                            Preparation of Unsolicited Proposals (PPP)
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={10}>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={4} sm={3} className={classes.businessScopeLogoGrid}>
                        <div className={classes.businessScopeLogo5} />
                      </Grid>

                      <Grid item xs={8} sm={9} className={classes.businessScopeRight}>
                        <Grid
                          container
                          direction="column"
                        >
                          <Grid item xs={12}>
                            Project Investment Packaging
                          </Grid>

                          <Grid item xs={12} className={classes.businessScopeSubtitle}>
                            Securing funding and building credibility
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={10}>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={4} sm={3} className={classes.businessScopeLogoGrid}>
                        <div className={classes.businessScopeLogo6} />
                      </Grid>

                      <Grid item xs={8} sm={9} className={classes.businessScopeRight}>
                        <Grid
                          container
                          direction="column"
                        >
                          <Grid item xs={12}>
                            Project Engineering, Execution & Operation
                          </Grid>

                          <Grid item xs={12} className={classes.businessScopeSubtitle}>
                            Efficient implementation of projects through Project Management
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Hidden>

            <Hidden only={['xs', 'sm']}>
              <Grid item xs={10}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                  spacing={2}
                >

                  <Grid item xs={12} md={6}>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={9} className={classes.businessScopeLeft}>
                        <Grid
                          container
                          direction="column"
                        >
                          <Grid item xs={12}>
                            Technical and Financial Advisory
                          </Grid>

                          <Grid item xs={12} className={classes.businessScopeSubtitle}>
                            Concept planning and business planning
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={3} className={classes.businessScopeLogoGrid}>
                        <div className={classes.businessScopeLogo1} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={3} className={classes.businessScopeLogoGrid}>
                        <div className={classes.businessScopeLogo2} />
                      </Grid>

                      <Grid item xs={9} className={classes.businessScopeRight}>
                        <Grid
                          container
                          direction="column"
                        >
                          <Grid item xs={12}>
                            Training and Capacity Building
                          </Grid>

                          <Grid item xs={12} className={classes.businessScopeSubtitle}>
                            Providing expertise and knowledge transfer
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={9} className={classes.businessScopeLeft}>
                        <Grid
                          container
                          direction="column"
                        >
                          <Grid item xs={12}>
                            Project Scoping
                          </Grid>

                          <Grid item xs={12} className={classes.businessScopeSubtitle}>
                            Streamlining client needs to manage risks
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={3} className={classes.businessScopeLogoGrid}>
                        <div className={classes.businessScopeLogo3} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={3} className={classes.businessScopeLogoGrid}>
                        <div className={classes.businessScopeLogo4} />
                      </Grid>

                      <Grid item xs={9} className={classes.businessScopeRight}>
                        <Grid
                          container
                          direction="column"
                        >
                          <Grid item xs={12}>
                            Project Development and Packaging
                          </Grid>

                          <Grid item xs={12} className={classes.businessScopeSubtitle}>
                            Preparation of Unsolicited Proposals (PPP)
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={9} className={classes.businessScopeLeft}>
                        <Grid
                          container
                          direction="column"
                        >
                          <Grid item xs={12}>
                            Project Investment Packaging
                          </Grid>

                          <Grid item xs={12} className={classes.businessScopeSubtitle}>
                            Securing funding and building credibility
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={3} className={classes.businessScopeLogoGrid}>
                        <div className={classes.businessScopeLogo5} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Grid
                      container
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={3} className={classes.businessScopeLogoGrid}>
                        <div className={classes.businessScopeLogo6} />
                      </Grid>

                      <Grid item xs={9} className={classes.businessScopeRight}>
                        <Grid
                          container
                          direction="column"
                        >
                          <Grid item xs={12}>
                            Project Engineering, Execution & Operation
                          </Grid>

                          <Grid item xs={12} className={classes.businessScopeSubtitle}>
                            Efficient implementation of projects through Project Management
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
            </Hidden>

          </Grid>
        </div>

        <div>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.businessScopeGrid}
          >
            <Grid item xs={10}>
                <div className={classes.experiences}>
                  OUR EXPERIENCES
                </div>
                <div className={classes.dividerGrid}>
                  <Divider variant="middle" className={classes.experiencesDivider} />
                </div>
            </Grid>
          </Grid>
        </div>


        <div>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.businessScopeGrid}
          >
            <Grid item xs={7} className={classes.experienceHeader}>
                Water Supply System Operations and Management
            </Grid>
          </Grid>
        </div>

        <div>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.projectItemGrid}
          >
            <Grid item xs={10}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12} className={classes.projectGrid}>
                  <div className={classes.projectTitle}>
                    Rio Tuba, Bataraza, Palawan Water Supply System
                  </div>

                  <div className={classes.projectDescription}>
                    TWSI, through Rio Tuba Total Water Solutions Corp. (RT Water), manages the overall operations and management of the Level III water distribution system of Barangay Rio Tuba, Bataraza, Palawan with potential 23,000 household connections by 2045. TWSI also acts as the primary consultant of the Rio Tuba Council in the design of the water distribution system, development of water sources, and construction and installation of transmission and distribution lines.
                  </div>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </div>


        <div>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.businessScopeGrid}
          >
            <Grid item xs={10}>
                <div className={classes.dividerGrid}>
                  <Divider variant="middle" className={classes.experiencesDivider} />
                </div>
            </Grid>
          </Grid>
        </div>


        <div>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={7} className={classes.experienceHeader}>
                Groundwater Exploration
            </Grid>
          </Grid>
        </div>

        <div>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.projectItemGrid}
          >
            <Grid item xs={10}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                // spacing={2}
              >
                <Grid item xs={12}>
                  <div className={classes.projectTitle}>
                    Hydrogeological Investigation, Groundwater Exploration and Development, Construction of Monitoring Wells for the Development of Master Plan for the Metro Cebu Water District <span className={classes.groundwaterSubtitle}>(Cebu City, Philippines; Sep 2009)</span>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.projectTitle}>
                    Rural Water Supply and Sanitation Project V, Groundwater Exploration and Development in the provinces of Ilocos Sur, Nueva Vizcaya, Occ. Mindoro and Or. Mindoro <span className={classes.groundwaterSubtitle}>(Jun to Aug 2008)</span>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.projectTitle}>
                    Groundwater Exploration, Development and Testing for the Small Water District Improvement Project in the Republic of the Philippines, Group 2 <span className={classes.groundwaterSubtitle}>(Aug 2007)</span>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.projectTitle}>
                    Groundwater Exploration, Development and Testing for the Small Water District Improvement Project in the Republic of the Philippines, Group 1 <span className={classes.groundwaterSubtitle}>(Sep 2006)</span>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.projectTitle}>
                    Construction of Complete Water System, Source Exploration and Development, Storage Tank, Transmission System and Distribution System <span className={classes.groundwaterSubtitle}>(San Manuel, Pangasinan; Jun 1998 to Mar 1999)</span>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.projectTitle}>
                    Evaluation/Rationalization of Operation and Assessment of Existing Water-Supply System <span className={classes.groundwaterSubtitle}>(Dasmariñas, Cavite; Jan 1998)</span>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.projectTitle}>
                    Assessment and Redevelopment Water Sources <span className={classes.groundwaterSubtitle}>(Taguig City, Metro Manila; Jan 1997)</span>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.projectTitle}>
                    Groundwater and Surface Water Source Development for LIMA Land Technology Center <span className={classes.groundwaterSubtitle}>(Malvar, Batangas; Jan 1997)</span>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.projectTitle}>
                    Development and Testing of Water Supply Sources for Fort Bonifacio Development Corporation <span className={classes.groundwaterSubtitle}>(Makati City, Metro Manila; Nov 1996)</span>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.projectTitle}>
                    Source Development, Light Industry & Science Park of the Philippines <span className={classes.groundwaterSubtitle}>(Cabuyao, Laguna; Aug 1996)</span>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.projectTitle}>
                    Source Development and Construction of Storage Tank Capacity = 10,000 gallons, for Philippine Geothermal, Inc. <span className={classes.groundwaterSubtitle}>(Laguna, Dec 1995)</span>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className={classes.projectTitle}>
                    Construction of Pumping Station and Installation of Transmission Lines and Appurtenances at Manila-Japanese School <span className={classes.groundwaterSubtitle}>(Parañaque City, Metro Manila; Oct 1994)</span>
                  </div>
                </Grid>

              </Grid>
            </Grid>

          </Grid>
        </div>


        <div>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.businessScopeGrid}
          >
            <Grid item xs={10}>
                <div className={classes.dividerGrid}>
                  <Divider variant="middle" className={classes.experiencesDivider} />
                </div>
            </Grid>
          </Grid>
        </div>


        <div>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.businessScopeGrid}
          >
            <Grid item xs={7} className={classes.experienceHeader}>
                Consulting Services
            </Grid>

            <Grid item xs={8} sm={7} md={7} lg={6} className={classes.experienceContent}>
              TWSI accepts, on a selective basis, consulting work for the conduct of water supply studies for clients, particularly those that are open to concession arrangements, joint venture or other forms of business collaboration. The work includes technical assessment, development planning and financial analysis, proposal development, among others, that will aid a potential investor in making a business decision. Through our close partnership with CEST Incorporated, TWSI is able to undertake large consulting work such as engineering plans and studies and detailed design, including construction-ready design.
            </Grid>

          </Grid>
        </div>

        <div>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Paper square={true} elevation={0} className={classes.csGrid}>
              </Paper>
            </Grid>
          </Grid>
        </div>

        <div>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.businessScopeGrid}
          >
            <Grid item xs={10}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={4}
              >
                <Grid item xs={12} md={6} className={classes.projectGrid}>
                  <div className={classes.projectTitle}>
                    Kota Kinabalu (Sabah, Malaysia) Sanitation/Wastewater Project Study
                  </div>

                  <div className={classes.projectDescription}>
                    Upgrading of the wastewater treatment facilities and replacement/construction of sewerage pipelines to abate the continuous pollution of Likas Bay and protect the health of the population around the Lake.
                  </div>
                </Grid>

                <Grid item xs={12} md={6} className={classes.projectGrid}>
                  <div className={classes.projectTitle}>
                    Bohol-Cebu Bulk Water Supply
                  </div>

                  <div className={classes.projectDescription}>
                    Design, construction and, operation and maintenance of an efficient bulk water system and water distribution system in Inabanga that will provide adequate, safe, and sustainable water to domestic, commercial, and institutional consumers in partnership with the Local Government Unit (LGU). The system will provide 180.65 MLD of domestic water for Inabanga Municipality and Metro Cebu Water District. The treated water will be transported from Bohol to Cebu via marine transportation. Utilizing the study as an Unsolicited Proposal, the Client was awarded the Original Proponent Status by the LGU.
                  </div>
                </Grid>

                <Grid item xs={12} md={6} className={classes.projectGrid}>
                  <div className={classes.projectTitle}>
                    Norzagaray Angat (Bulacan) Bulk Water Supply and Optimization Project Feasibility Study
                  </div>

                  <div className={classes.projectDescription}>
                    Construction of a 20-meter dam upstream the excavation of the downstream river to impound excess water along the Angat-Norzagaray, especially during heavy rainfall and extreme flood events, for supply of 800 MLD to Metro Manila. Submitted as USP to MWSS.
                  </div>
                </Grid>

                <Grid item xs={12} md={6} className={classes.projectGrid}>
                  <div className={classes.projectTitle}>
                    Candaba, Pampanga Water Supply Development Study
                  </div>

                  <div className={classes.projectDescription}>
                    Development of seven additional deep well water sources, and a 15-MLD bulkwater source in Pampanga River that will provide water supply to 38,000 households by 2045. The Project will also include the development of the wastewater facilities for the municipality. The study was utilized as a USP and resulted in a Joint Venture Agreement between the Client and Water District.
                  </div>
                </Grid>

                <Grid item xs={12} md={6} className={classes.projectGrid}>
                  <div className={classes.projectTitle}>
                    Masantol, Pampanga Water Supply Development Study
                  </div>

                  <div className={classes.projectDescription}>
                    Development of six additional water sources that will provide water supply to 18,000 households by 2045. The Project will also include massive pipeline replacement for the existing water supply pipeline. The study was utilized as a USP and resulted in a Joint Venture Agreement between the Client and Water District.
                  </div>
                </Grid>

                <Grid item xs={12} md={6} className={classes.projectGrid}>
                  <div className={classes.projectTitle}>
                    Bacolor, Pampanga Water Supply Development Study
                  </div>

                  <div className={classes.projectDescription}>
                    Development of seven additional water sources that will provide water supply to 10,000 households by 2045. The Project will also include massive pipeline replacement for the existing water supply pipeline. The study was utilized as a USP and resulted in a Joint Venture Agreement between the Client and Water District.
                  </div>
                </Grid>

                <Grid item xs={12} md={6} className={classes.projectGrid}>
                  <div className={classes.projectTitle}>
                    Cabiao, Nueva Ecija Water Supply Development Study
                  </div>

                  <div className={classes.projectDescription}>
                    Development of eight additional water sources that will provide water supply to 15,000 households by 2045. The Project will also include massive pipeline replacement for the existing water supply pipeline. The study was utilized as a USP and resulted in a Joint Venture Agreement between the Client and Water District.
                  </div>
                </Grid>

                <Grid item xs={12} md={6} className={classes.projectGrid}>
                  <div className={classes.projectTitle}>
                    Pila, Laguna
                  </div>

                  <div className={classes.projectDescription}>
                    Development of six additional water sources that will provide water supply to 10,000 households by 2045. The Project will also include asset improvement as well as service efficiency improvement of the existing water supply system. The client was successful in securing a Joint Venture Agreement with the LGU.
                  </div>
                </Grid>

                <Grid item xs={12} md={6} className={classes.projectGrid}>
                  <div className={classes.projectTitle}>
                    San Leonardo, Nueva Ecija Water Supply Development Study
                  </div>

                  <div className={classes.projectDescription}>
                  Development of eleven additional water sources that will provide water supply to 20,000 households by 2045. The Project will also include the improvement in the service efficiency of the existing water supply system and reduction of non-revenue water (NRW). The study was utilized as a USP and resulted in a Joint Venture Agreement between the Client and Water District.
                  </div>
                </Grid>

              </Grid>
            </Grid>

          </Grid>
        </div>


        <div>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.businessScopeGrid}
          >
            <Grid item xs={10}>
                <div className={classes.dividerGrid}>
                  <Divider variant="middle" className={classes.experiencesDivider} />
                </div>
            </Grid>
          </Grid>
        </div>


        <div>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.businessScopeGrid}
          >
            <Grid item xs={7} className={classes.experienceHeader}>
                Project Development
            </Grid>

            <Grid item xs={8} sm={7} md={7} lg={5} className={classes.experienceContent}>
                TWSI identifies and selects small-size municipalities/communities that are faced with water supply service issues, e.g., quantity, quality, efficiency, etc. In doing business, TWSI has preference on local governments with good governance practices, development-oriented leadership, among others. TWSI validates the business potential of an opportunity by investing its funds in conducting studies.
            </Grid>

          </Grid>
        </div>

        <div>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} className={classes.pdGrid} />
          </Grid>
        </div>

        <div>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.businessScopeGrid}
          >
            <Grid item xs={10}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={4}
              >
                <Grid item xs={12} md={6} className={classes.projectGrid}>
                  <div className={classes.projectTitle}>
                    Baco Supply Water Project
                    <br />
                    (Or. Mindoro)
                  </div>

                  <div className={classes.projectDescription}>
                    Development of a water source in Apapaa River with an estimated discharge of 100 MLD to supply around 10,500 service connections in 2045 in Baco and the adjacent town.
                  </div>
                </Grid>

                <Grid item xs={12} md={6} className={classes.projectGrid}>
                  <div className={classes.projectTitle}>
                    Puerto Galera Water Supply & Sanitation Project
                    <br />
                    (Or. Mindoro)
                  </div>

                  <div className={classes.projectDescription}>
                    Improvement, rehabilitation and expansion of the municipal-wide water supply, wastewater, and solid waste management facilities to benefit around 10,000 households by year 2045.
                  </div>
                </Grid>

                <Grid item xs={12} md={6} className={classes.projectGrid}>
                  <div className={classes.projectTitle}>
                    Bulalacao Water Supply & Sanitation Project
                    <br />
                    (Or. Mindoro)
                  </div>

                  <div className={classes.projectDescription}>
                    Development of a water source that will provide water supply to 15,000 households in 15 barangays by 2045. The Project will also include the development of the wastewater and solid waste management facilities.
                  </div>
                </Grid>

                <Grid item xs={12} md={6} className={classes.projectGrid}>
                  <div className={classes.projectTitle}>
                    Pinamalayan Bulk Water Supply Project
                    <br />
                    (Or. Mindoro)
                  </div>

                  <div className={classes.projectDescription}>
                    Development of a water source that will provide water supply to 24,000 households by 2045. The Project will also include the development of the wastewater and solid waste management facilities for the municipality.
                  </div>
                </Grid>

                <Grid item xs={12} md={6} className={classes.projectGrid}>
                  <div className={classes.projectTitle}>
                    Basista Water Supply Project
                    <br />
                    (Pangasinan)
                  </div>

                  <div className={classes.projectDescription}>
                    Development of a bulk ground water source that will provide water supply to 10,000 households by 2045.
                  </div>
                </Grid>

                <Grid item xs={12} md={6} className={classes.projectGrid}>
                  <div className={classes.projectTitle}>
                    Rosario Water Supply Project
                    <br />
                    (La Union)
                  </div>

                  <div className={classes.projectDescription}>
                    Development of a bulk ground water source that will provide water supply to 4,000 households by 2045.
                  </div>
                </Grid>

              </Grid>
            </Grid>

          </Grid>
        </div>


        <div>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.businessScopeGrid}
          >
            <Grid item xs={10}>
                <div className={classes.dividerGrid}>
                  <Divider variant="middle" className={classes.experiencesDivider} />
                </div>
            </Grid>
          </Grid>
        </div>


        <div>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.businessScopeGrid}
          >
            <Grid item xs={7} className={classes.experienceHeader}>
                Construction Services
            </Grid>

            <Grid item xs={8} sm={7} md={7} lg={5} className={classes.experienceContent}>
                TWSI also provides construction services in the form of construction supervision, supply chain management, and overall project management to small – medium sized construction projects. The work includes engineering design, contractor assessment and selection, supplier assessment and selection, development planning and value engineering, among others, that will ensure that a project is completed in a timely manner and within budget.
            </Grid>

          </Grid>
        </div>

        <div>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} className={classes.constructionGrid} />
          </Grid>
        </div>

        <div>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.businessScopeGrid}
          >
            <Grid item xs={10}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
                spacing={4}
              >
                <Grid item xs={12} md={6} className={classes.projectGrid}>
                  <div className={classes.projectTitle}>
                    Kinurong Pipeline
                  </div>

                  <div className={classes.projectDescription}>
                    Design and construction of a 2.5 km long water main in Brgy. Rio Tuba, Bataraza, Palawan that provides level III water distribution access to 600 households.
                  </div>
                </Grid>

                <Grid item xs={12} md={6} className={classes.projectGrid}>
                  <div className={classes.projectTitle}>
                    Amadeo Site Development
                  </div>

                  <div className={classes.projectDescription}>
                    Design, construction, and rehabilitation of a main gate, perimeter fence, primary access road and construction supervision for a hydroponics farm located on a property of CEST, Inc. in Amadeo, Cavite. The project also involved quantity surveying and the analysis of bids from contractors and suppliers.
                  </div>
                </Grid>

              </Grid>
            </Grid>

          </Grid>
        </div>

        <Footer credit={false} />
      </div>
    )
  }
})))
