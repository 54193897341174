import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { withStyles } from '@material-ui/styles'
import { Hidden, Grid, Paper, Button } from '@material-ui/core'

import AppBar from '../components/AppBar'
import Footer from '../components/Footer'
import ContactForm from '../Contact/ContactForm'

const styles = theme => ({
  toolbar: theme.mixins.toolbar,
  grid: {
    backgroundColor: '#c0dbf1',
  },
  introGrid: {
    backgroundImage: `url(${require('../../assets/bg_intro.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    marginTop: 20,
  },
  // size: xs
  introPaper1x: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    // minWidth: 450,
    minHeight: 250,
    marginTop: 30,
    marginBottom: 30,
    color: '#c0dbf1',
    fontWeight: 700,
    fontSize: 40,
    background: 'none',
  },
  // size: sm
  introPaper2x: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minWidth: 450,
    minHeight: 250,
    marginTop: 30,
    marginBottom: 30,
    color: '#c0dbf1',
    fontWeight: 700,
    fontSize: 50,
    background: 'none',
  },
  // size: md, lg, xl
  introPaper3x: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    minWidth: 450,
    minHeight: 250,
    marginTop: 30,
    marginBottom: 30,
    marginRight: 80,
    color: '#c0dbf1',
    fontWeight: 700,
    fontSize: 60,
    background: 'none',
  },
  header: {
    marginTop: 30,
    textAlign: 'center',
    color: '#a77c50',
    fontWeight: 700,
    fontSize: 20,
    letterSpacing: 6,
  },
  aboutGrid: {
    textAlign: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  aboutContent: {
    marginTop: 15,
    marginBottom: 15,
    fontWeight: 300,
    fontSize: 18,
  },
  dividerGrid: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 15,
    marginBottom: 15,
  },
  divider: {
    backgroundColor: '#3a85c5',
    height: 10,
    width: 10,
    borderRadius: 50,
    marginLeft: 8,
    marginRight: 8,
  },
  businessScopeGrid: {
    textAlign: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  businessScopeContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    fontSize: 20,
  },

  businessScopeLogo1: {
    backgroundImage: `url(${require('../../assets/bs1.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 200,
    width: 200,
    marginTop: 10,
    marginBottom: 10,
  },
  businessScopeLogo2: {
    backgroundImage: `url(${require('../../assets/bs2.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 200,
    width: 200,
    marginTop: 10,
    marginBottom: 10,
  },
  businessScopeLogo3: {
    backgroundImage: `url(${require('../../assets/bs3.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 200,
    width: 200,
    marginTop: 10,
    marginBottom: 10,
  },
  businessScopeLogo4: {
    backgroundImage: `url(${require('../../assets/bs4.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 200,
    width: 200,
    marginTop: 10,
    marginBottom: 10,
  },
  businessScopeLogo5: {
    backgroundImage: `url(${require('../../assets/bs5.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 200,
    width: 200,
    marginTop: 10,
    marginBottom: 10,
  },
  businessScopeLogo6: {
    backgroundImage: `url(${require('../../assets/bs6.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: 200,
    width: 200,
    marginTop: 10,
    marginBottom: 10,
  },

  contactGrid: {
    backgroundImage: `url(${require('../../assets/bg_contact.png')})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    marginTop: 30,
  },
  contactContent: {
    backgroundColor: '#ffffff',
    textAlign: 'center',
    padding: 50,
    marginTop: 15,
    marginBottom: 40,
    fontWeight: 300,
    fontSize: 18,
  },
  contactMargin: {
    marginTop: 40,
  },
  contactButton: {
    backgroundColor: '#a77c50',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: 50,
    fontWeight: 700,
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#a77c50',
    },
  },
  footerGrid: {
    backgroundColor: '#f6f6f6',
    padding: 20,
  },
  footer: {
    fontSize: 12,
    color: '#6e6e6e',
  },
})

export default withRouter((withStyles(styles)(class extends Component {
  constructor() {
    super()
    this.state = {
      open: false
    }
  }

  handleOnClick = () => {
    this.setState({
      open: true
    })
  }

  handleClose = () => {
    this.setState({
      open: false
    })
  }

  render() {
    const {
      classes,
      title,
      description
    } = this.props

    const {
      open
    } = this.state

    return (
      <div className={classes.background}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>

        <AppBar
          home={true}
          about={false}
          services={false}
          contact={false}
        />

        <ContactForm
          open={open}
          handleClose={this.handleClose}
        />

        <div>
          <Hidden only={['sm', 'md', 'lg', 'xl']}>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.introGrid}
              >
              <Grid item xs={12}>
                <Paper square={true} elevation={0} className={classes.introPaper1x}>
                  We are in the <br /> Water Business
                </Paper>
              </Grid>
            </Grid>
          </Hidden>

          <Hidden only={['xs', 'md', 'lg', 'xl']}>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.introGrid}
              >
              <Grid item xs={12}>
                <Paper square={true} elevation={0} className={classes.introPaper2x}>
                  We are in the<br/ >Water Business
                </Paper>
              </Grid>
            </Grid>
          </Hidden>

          <Hidden only={['xs', 'sm']}>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-end"
                className={classes.introGrid}
              >
              <Grid item xs={12}>
                <Paper square={true} elevation={0} className={classes.introPaper3x}>
                  We are in the Water Business
                </Paper>
              </Grid>
            </Grid>
          </Hidden>
        </div>

        <div>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.aboutGrid}
          >
            <Grid item xs={7} className={classes.header}>
                ABOUT
            </Grid>

            <Grid item xs={8} sm={7} md={7} lg={5} className={classes.aboutContent}>
                We are engaged in the business of water supply purification, bulk supply & distribution, and wastewater & biosolids treatment and disposal.
            </Grid>

            <Grid item xs={7} className={classes.dividerGrid}>
              <div className={classes.divider} />
              <div className={classes.divider} />
              <div className={classes.divider} />
            </Grid>

            <Grid item xs={8} sm={7} md={7} lg={5} className={classes.aboutContent}>
                TWSI shareholders and officers are engineers and professionals with long experiences and wide exposure in water and sanitation consulting industry, in the fields of planning, engineering, economics, training, and environment.
            </Grid>

          </Grid>
        </div>

        <div>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.businessScopeGrid}
          >
            <Grid item xs={7} className={classes.header}>
                BUSINESS SCOPE
            </Grid>

            <Grid item xs={10}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
                spacing={4}
              >

                <Grid item xs={12} sm={6} md={4} className={classes.businessScopeContent}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <div className={classes.businessScopeLogo1} />
                    </Grid>

                    <Grid item xs={12}>
                      Technical and Financial Advisory
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6} md={4} className={classes.businessScopeContent}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <div className={classes.businessScopeLogo2} />
                    </Grid>

                    <Grid item xs={12}>
                      Training and Capacity Building
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6} md={4} className={classes.businessScopeContent}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <div className={classes.businessScopeLogo3} />
                    </Grid>

                    <Grid item xs={12}>
                      Project Scoping
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6} md={4} className={classes.businessScopeContent}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <div className={classes.businessScopeLogo4} />
                    </Grid>

                    <Grid item xs={12}>
                      Project Development and Packaging
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6} md={4} className={classes.businessScopeContent}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <div className={classes.businessScopeLogo5} />
                    </Grid>

                    <Grid item xs={12}>
                      Project Investment Packaging
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={6} md={4} className={classes.businessScopeContent}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12}>
                      <div className={classes.businessScopeLogo6} />
                    </Grid>

                    <Grid item xs={12}>
                      Project Engineering, Execution & Operation
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </div>

        <div>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.contactGrid}
          >
            <Grid item xs={7} className={classes.header}>
                CONTACT US
            </Grid>

            <Grid item xs={10} sm={9} md={7} >
              <Paper square={true} elevation={0} className={classes.contactContent}>


                <Grid item xs={12}>
                  Unit 303 Prestige Tower, F. Ortigas Jr. Road, Ortigas Center, Pasig City
                </Grid>

                <Grid item xs={12} className={classes.contactMargin}>
                  0917 - 508 - TWSI (8974)
                </Grid>

                <Grid item xs={12}>
                  info@totalwatersolutions-inc.com
                </Grid>

                <Grid item xs={12} className={classes.contactMargin}>
                  <Button onClick={this.handleOnClick} className={classes.contactButton}>
                    SEND US A MESSAGE
                  </Button>
                </Grid>

              </Paper>
            </Grid>

          </Grid>
        </div>

        <Footer credit={false} />
      </div>
    )
  }
})))
