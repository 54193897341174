import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import { withStyles } from '@material-ui/styles'
import { CssBaseline, useScrollTrigger, AppBar, Toolbar, Grid, Paper } from '@material-ui/core'

const styles = theme => ({
  appBar: {
    position: 'relative',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    boxShadow: 'none',
    fontSize: 12,
  },
  logo: {
    backgroundImage: `url(${require('../../../assets/logo_tws.png')})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: 250,
    height: 150,
  },
  logoGrid: {
    display: 'flex',
    justifyContent: 'center',
    background: 'none',
    textAlign: 'center',
  },
  inactivePage: {
    marginTop: 10,
    marginBottom: 15,
    background: 'none',
    textAlign: 'center',
    fontWeight: 300,
  },
  activePage: {
    marginTop: 10,
    marginBottom: 15,
    background: 'none',
    textAlign: 'center',
    fontWeight: 700,
    color: '#a77c50',
  },
})

function ElevationScroll(props) {
  const {
    children, window
  } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

export default withStyles(styles)(class extends Component {
  // constructor() {
  //   super()
  // }

  render() {
    const {
      props,
      classes,
      home,
      about,
      services,
      contact
    } = this.props

    return (
      <div>
        <CssBaseline />
        <ElevationScroll {...props}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >

                <Grid item xs={12} className={classes.logoGrid}>
                    <Link to='/'>
                      <div className={classes.logo} />
                    </Link>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Link
                    to='/'
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    <Paper elevation={0} className={clsx(classes.inactivePage, {
                      [classes.activePage] : home,
                    })}>
                      HOME
                    </Paper>
                  </Link>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Link
                    to='/about'
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    <Paper elevation={0} className={clsx(classes.inactivePage, {
                      [classes.activePage] : about,
                    })}>
                      ABOUT
                    </Paper>
                  </Link>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Link
                    to='/services'
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    <Paper elevation={0} className={clsx(classes.inactivePage, {
                      [classes.activePage] : services,
                    })}>
                      SERVICES
                    </Paper>
                  </Link>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Link
                    to='/contact-us'
                    style={{ textDecoration: 'none', color: 'black' }}
                  >
                    <Paper elevation={0} className={clsx(classes.inactivePage, {
                      [classes.activePage] : contact,
                    })}>
                      CONTACT US
                    </Paper>
                  </Link>
                </Grid>


              </Grid>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
      </div>
    )
  }
})
